import React from 'react';
import Layout from '../layouts/layout';
import Grid from '../components/Grid';
import LocationButton from '../components/LocationButton';

export default function Menu(props) {
	return (
		<Layout title="Menu">
			<h1>Menu</h1>
			<Grid>
				<div>
					<div className="chalk">Sit down & stay for a while</div>
					<LocationButton
						className="pink-button"
						href="/menu-vista-downtown"
					>
						<span className="sr-only">Menu for </span>
						Downtown Vista
					</LocationButton>
				</div>
				<div>
					<div className="chalk">
						Outdoor patio seating & take-out
					</div>
					<LocationButton
						className="pink-button"
						href="/menu-vista-foothill"
					>
						<span className="sr-only">Menu for </span> Foothill
						(Temporarily Closed)
					</LocationButton>
				</div>
			</Grid>
		</Layout>
	);
}
